import React, {useEffect} from 'react';
import "../../helpers/header/Header.css"
import "./Roadmap.css"
import "./Teams.css"
import teamfounder from "../../assets/images/teamfounder.svg"
import teampc from "../../assets/images/Teampc.svg"
import teamuxw from "../../assets/images/teamuxw.svg"

import x21 from "../../assets/images/x211.png"
import au21 from "../../assets/images/au211.png"
import ccix from "../../assets/images/CCIX1.png"
import wsb from "../../assets/images/newWSB.jpeg"
import inv1 from "../../assets/images/inv1.jpeg"
import vb from "../../assets/images/vb.jpeg"
import magnus from "../../assets/images/Magnus1.png"
import maven from "../../assets/images/maven.png"
import ava from "../../assets/images/ava.png"
import poolz from "../../assets/images/poolz.webp"
import redhat from "../../assets/images/redhat.png"
import basics from "../../assets/images/Basics.png"
import zbs from "../../assets/images/zbs.jpeg"



import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import AOS from 'aos';
const getTeamsCard = (isDown, headerText, pic, names, details) => {
    return (
        <div className={isDown ? "teams-card-div down-card " : "teams-card-div"}>
            <div className="team-header">
                <p>{headerText}</p>
            </div>
            <div className="team-picture">
                <img src={pic} alt="" />
            </div>
            <p className="teammember">{names}</p> 
            <p className="teammember-details">{details}</p>           
        </div>
    );
}
const Teams = () => {

useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);
    return (
        <>
            <div className="teammember-div">

            </div>
            <div className="investors">
                <h1 className="investors-title">Investors</h1>
                <div className="investors-div" data-aos="fade-up">
                    <div className="logo-cover"><img src={x21} alt="" className="x21-img"/></div>
                    <div className="logo-cover"><img src={au21} alt="" className="investor-img1" /></div>
                    <div className="logo-cover"><img src={magnus} alt="" className="magnus-img" /></div>
                    <div className="logo-cover"><img src={ccix} alt="" className="investor-img1" /></div>
                    <div className="logo-cover"><img src={wsb} alt="" className="investor-img" /></div>
                    <div className="logo-cover"><img src={inv1} alt="" className="investor-img" /></div>
                    <div className="logo-cover"><img src={vb} alt="" className="investor-img" /></div>
                    <div className="logo-cover"><img src={poolz} alt="" className="investor-img1" /></div>
                    <div className="logo-cover"><img src={maven} alt="" className="investor-img2" /></div>
                    <div className="logo-cover"><img src={ava} alt="" className="investor-img2" /></div>
                    <div className="logo-cover"><img src={redhat} alt="" className="investor-img1" /></div>
                    <div className="logo-cover"><img src={basics} alt="" className="investor-img1" /></div>
                    <div className="logo-cover"><img src={zbs} alt="" className="investor-img" /></div>
                </div>
                <div className="footer">
                    <div className="copyright-div">
                        © 2021 Furukuru
                    </div>
                    <div className="socials">
                        <div className="social-twitter">
                            <a href="https://twitter.com/furu_kuru"  target="_blank"  rel="noreferrer"><TwitterIcon className="square-icon"/></a>
                        </div>

                    </div>
                    <div className="email-div">
                        Email: <a href="mailto:contact@furukuru.io" target="_blank"  rel="noreferrer">contact@furukuru.io</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Teams;