

import "../../helpers/header/Header.css"
import "./BuildSquad.css"
import leftToon from "../../assets/images/leftToon.svg"
// import leftArrow from "../../assets/images/leftArrow.svg"
// import rightArrow from "../../assets/images/rightArrow.svg"
// import leftIcon from "../../assets/images/left.png"
// import rightIcon from "../../assets/images/right.png"
import React, { useState, useEffect } from 'react';
import AOS from 'aos';

const BuildSquad = () => {
    const [firstChar, setFirstChar] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
            setFirstChar((firstChar) => !firstChar);
           
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    return (
        <>
            <section className="build-squad-banner">
                <div className="build-squad-left">
                    <img src={leftToon} alt="" className="left-toon" />
                </div>
                <div className="build-squad-right">
                    <h1 className="build-title">Utilities</h1>
                    <div className="build-canvas">
                        {/* <div className="arrows1" onClick={() => setFirstChar(!firstChar)}>
                            <img src={leftArrow} alt="" className="left-arrow1" />
                            <img src={leftIcon} alt="" className="arrowIcon1" />
                        </div> */}
                        {firstChar ?
                            <div className="util-div"  data-aos="fade-up">
                                <h1 className="util-title" >FURU & KURU</h1>
                                <div className="list-item-div">
                                    <div className="list-item">- Participate in Races and Future Games, Win FUKU Tokens</div>
                                    <div className="list-item">- NFT Breeding</div>
                                    <div className="list-item">- Easter Eggs </div>
                                </div>
                            </div>
                            :
                            <div className="util-div"  data-aos="fade-up">
                                <h1 className="util-title">FUKU</h1>
                                <div className="list-item-div">
                                    <div className="list-item">- Stake to win NFTs</div>
                                    <div className="list-item">- Game Rewards </div>
                                    <div className="list-item">- LP Rewards</div>
                                    <div className="list-item">- Buy ingame accessories </div>
                                    <div className="list-item">- Feed NFT Characters</div>
                                </div>
                            </div>
                        }
                        {/* <div className="arrows1" onClick={() => setFirstChar(!firstChar)}>
                            <img src={rightArrow} alt="" className="right-arrow1" />
                            <img src={rightIcon} alt="" className="arrowIcon2" />
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default BuildSquad;