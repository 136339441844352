import React,{useEffect} from 'react';
import "../../helpers/header/Header.css"
import "./Banner.css"
import cloud1 from "../../assets/images/cloud.svg"
import cloud2 from "../../assets/images/cloud2.svg"
import cloud3 from "../../assets/images/cloud3.svg"
import cloud4 from "../../assets/images/cloud4.svg"
import cloud5 from "../../assets/images/cloud5.svg"
import cloud6 from "../../assets/images/cloud6.svg"
import baloon1 from "../../assets/images/baloon1.svg"
import baloon2 from "../../assets/images/baloon2.svg"
import baloon3 from "../../assets/images/baloon3.svg"
import AOS from 'aos';
import furukurulogo from '../../assets/images/furukurulogo.svg'
const Banner = () => {
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    return(
        <>           
           <section className="banner"> 
                <img src={cloud1} alt="" className="cloud1"></img>  
                <img src={cloud2} alt="" className="cloud2"></img>   
                <img src={cloud3} alt="" className="cloud3"></img>   
                <img src={cloud5} alt="" className="cloud4"></img>   
                <img src={cloud4} alt="" className="cloud5"></img>   
                <img src={cloud6} alt="" className="cloud6"></img>  
                <img src={baloon1} alt="" className="baloon1"></img>   
                <img src={baloon2} alt="" className="baloon2"></img>   
                <img src={baloon3} alt="" className="baloon3"></img>  
                <div className="announcement">
                   
                </div>
                <div className="link-div">
                    <a href="https://pancakeswap.finance/swap?outputCurrency=0xec181b5f1d7b069192a3554bde509728b16d5d73" target="_blank">
                    <div className="link-btns">
                        <img src={furukurulogo} className="linkimg" alt="" />
                        <h3 className="buybtn" >Buy $FUKU </h3>
                    </div></a>
                    <a href="https://stake.furukuru.io"  target="_blank">
                        <div className="link-btns">
                        <img src={furukurulogo} className="linkimg" alt="" />
                            <h3 className="buybtn">Stake $FUKU</h3>
                        </div>
                    </a>
                </div>
                <div className="banner-hero">
                    {/* <h1>Our early world was in chaos. Many monster tribes lived on the same continents and oceans.</h1> */}
                    <h1 data-aos="fade-up">FuruKuru, a Tamagotchi game.</h1>
                    {/* <img src={downBtn} alt="" className="down-btn"/> */}
                </div>
                <span className="bannerbase">
                </span>
            </section>
        </>
    );
}
export default Banner;