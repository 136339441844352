
import "../../helpers/header/Header.css"
import "./MobileBanner.css"
import React,{useEffect} from 'react';
// import mobileImg from "../../assets/images/mobileImg.svg"
// import character1 from "../../assets/images/character1.svg"
import mobileVideo from "../../assets/images/mobileVideo2.mp4"
import AOS from 'aos';

const MobileBanner = () => {
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    return(
        <>
           <section className="mobile-banner" >
                <div className="mobile-banner-left">                   
                    <video className="mobile-layer" loop autoPlay muted>
                        <source src={mobileVideo}  className="videolayer" type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                </div>
                <div className="mobile-banner-right" data-aos="fade-up">
                    <h1 className="mobile-title">Gamble, Race and Win</h1>
                    <h3 className="mobile-text">Race with your pets to win FUKU tokens and limited edition NFTs.</h3>
                </div>
            </section>
        </>
    );
}

export default MobileBanner;