import React,{useEffect} from 'react';
import "../../helpers/header/Header.css"
import "./PlayToEarn.css"
import dgreen from "../../assets/images/dgreen.svg"
import dred from "../../assets/images/dred.svg"
import dpurple from "../../assets/images/dpurple.svg"
import dyellow from "../../assets/images/dyellow.svg"
import dblue from "../../assets/images/dblue.svg"
import rew1 from "../../assets/images/rew1.svg"
import rew2 from "../../assets/images/rew2.svg"
import rew3 from "../../assets/images/rew3.svg"
import rew4 from "../../assets/images/rew4.svg"
import rew5 from "../../assets/images/rew5.svg"
import AOS from 'aos';


// import { useHistory } from "react-router-dom";

const getCard = (CardImg, desc, bottomJewel) => {

    return (
        <div className="reward-card">
            <div className="card-img">
                <img src={CardImg} alt="" className="top-jewel" />
            </div>
            <div className="card-desc">
                <p className="card-text">{desc}</p>
            </div>
            <div className="bottom-jewel-div">
                <img src={bottomJewel} alt="" className="bottom-jewel" />
            </div>
        </div>
    );
}

const PlayToEarn = () => {
    // const history = useHistory();

    // const routeChange = () =>{
    //     let path = `/game`;
    //     history.push(path);
    // }

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    return (
        <>
            <div className="play-to-earn">
                <h1 className="play-earn-title"  >Play To Earn</h1>
                <div className="reward-div"  data-aos="fade-up">
                    {/* {getCard(rew1, "Let your monsters practice at the training ground to increase their level and receive $DML tokens even if you are not online.", dyellow)}
                    {getCard(rew2, "Complete daily missions for rewards", dblue)}
                    {getCard(rew3, "Compete with other players in the arena ranking for exclusive rewards", dpurple)}
                    {getCard(rew4, "Accompanying players around the world to defeat the world Boss and receive valuable rewards", dgreen)}
    {getCard(rew5, "Find unique monsters and items and exchange with other players.", dred)} */}
                    {getCard(rew1, "Limited Edition NFTs ", dyellow)}
                    {getCard(rew2, "Access Pass to Future Games", dblue)}
                    {getCard(rew3, "FUKU Tokens", dpurple)}
                    {getCard(rew4, "Accessories", dgreen)}
                    {getCard(rew5, "Redeemable Gem Stones", dred)}
                </div>
            </div>
        </>
    );
}

export default PlayToEarn;