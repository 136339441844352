
import React from 'react';
import "./Header.css"
const Navbar = () => {
    return (
        <>
            {/* <div className="navbar">
                <ul className="nav-item">
                    <li>MARKETPLACE<p className="comingsoon">Coming soon</p></li>
                    <li>STAKE<p className="comingsoon">Coming soon</p></li>
                    <li><a href="https://drive.google.com/file/d/1uW_H-JOZvQkxQeXQYTC5m_CffKluI4vn/view?usp=sharing" target="_blank" >PITCH DECK</a></li>
                    <li>OUR TEAM</li>
                      <li className="play-btn"><p className="comingsoon">Coming soon</p></li>  
                </ul>
            </div>  */}
            <nav className="navbar">
                <input type="checkbox" id="check" />
                <label htmlFor="check" className="checkbtn">
                    <i className="fas fa-bars"></i>
                </label>
                <label className="logo">FURUKURU</label>
                <ul className="nav-item">
                    {/* <li>MARKETPLACE<p className="comingsoon">Coming soon</p></li> */}
                    <li style={{marginLeft:"20px"}}><a href="https://stake.furukuru.io" target="_blank"  rel="noreferrer">STAKE</a></li>
        {/*<li><a href="https://docsend.com/view/u4fihn8ypfipig2n" target="_blank"  rel="noreferrer">PITCH DECK</a></li>*/}
                    <li></li>
                    {/* <p className="comingsoon">Coming soon</p> */}
                </ul>
            </nav>
        </>
    );
}

export default Navbar;
