import React, { useState, useEffect } from 'react';

import "../../helpers/header/Header.css"
import "./CreateNft.css"
// import leftArrow from "../../assets/images/leftArrow.svg"
// import rightArrow from "../../assets/images/rightArrow.svg"
import character2 from "../../assets/images/character1.svg"
import character1 from "../../assets/images/leftToon.svg"
// import leftIcon from "../../assets/images/left.png"
// import rightIcon from "../../assets/images/right.png"

const CreateNft = () => {
    const [firstChar, setFirstChar] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
            setFirstChar((firstChar) => !firstChar);
           
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return(
        <>
           <section className="createNft">
                <div className="createNft-top">
                    {/* <div className="arrows" onClick={() => setFirstChar(!firstChar)}>
                        <img src={leftArrow} alt="" className="left-arrow"/>
                        <img src={leftIcon} alt="" className="arrowIcon"/>
                    </div> */}
                    <div className="createbase">
                        <img src={firstChar ? character1 : character2} alt="" className="createtoon"/>
                    </div>
                    {/* <div className="arrows" onClick={() => setFirstChar(!firstChar)}>
                        <img src={rightArrow} alt="" className="right-arrow"/>
                        <img src={rightIcon} alt="" className="arrowIcon"/>
                    </div> */}
                </div>
                <div className="createNft-bottom">
                    <div className="create-btn">
                        <h1>{firstChar ? "Mint Furu" : "Mint Kuru"}<p className="create-comingsoon">Coming soon</p></h1>
                    </div>
                </div>
            </section>
        </>
    );
}
export default CreateNft;