import Navbar from "../src/helpers/header/Header"
import Banner from "./components/LandingPage/Banner"
import './App.css';
import MobileBanner from "./components/LandingPage/MobileBanner";
import BuildSquad from "./components/LandingPage/BuildSquad";
import CreateNft from "./components/LandingPage/CreateNft";
import PlayToEarn from "./components/LandingPage/PlayToEarn";
import Roadmap from "./components/LandingPage/Roadmap";
import Teams from "./components/LandingPage/Teams";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/">
            <Navbar />
            <Banner />
            <MobileBanner />
            <BuildSquad />
            {/* <PrizeSection/> */}
            <CreateNft />
            <PlayToEarn />
            {/* <Roadmap /> */}
              {/* <Teams />*/}
          </Route>
        </Switch>
      </Router>
    </>
  );
}
export default App;
